
import { defineComponent, ref, computed } from "vue";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import { useRouter } from "vue-router";
import store from "@/store";
import { ErrorMessage, Field, Form } from "vee-validate";
import GoBackButton from "@/components/reusable/GoBackButton.vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "settle-credit-note-component",
  components: {
    ErrorMessage,
    Field,
    Form,
    GoBackButton,
  },
  props: {
    returnRoute: String,
  },
  data() {
    return {
      publicId: this.$route.params.publicId,
      policySummary: {} as any,
      premium: "",
      paymentDate: "",
      currentDate: variables.currentDate(),
      startDate: "",
      route: "",
      paymentSchedule: "",
    };
  },
  setup(props) {
    const submitButton1 = ref<HTMLElement | null>(null);
    const router = useRouter();

    const user = computed(() => {
      return store.getters.currentUser;
    });

    const submitCreditNote = (values, { resetForm }) => {
      if (submitButton1.value) {
        // Activate indicator
        submitButton1.value.setAttribute("data-kt-indicator", "on");

        setTimeout(() => {
          submitButton1.value?.removeAttribute("data-kt-indicator");

          // Send personal information update request
          if (JwtService.getToken()) {
            ApiService.setHeader();
            ApiService.post(values["route"], values)
              .then((response) => {
                variables.toastAlert(response.data.data.message, "success");
                resetForm();
                // Redirect back to claims history page
                router.push({
                  name: props["returnRoute"],
                });
              })
              .catch(function(error) {
                variables.toastAlert(error.response.data.error, "error");
              });
          }
        }, 2000);
      }
    };

    return { submitButton1, submitCreditNote, user };
  },
  created() {
    const router = useRouter();
    const store = useStore();
    const userType = store.getters.currentUser;

    const today = new Date();
    this.startDate = today.getFullYear() + "-" + "01" + "-" + "01";

    // Check if publicId is passed as a prop
    if (this.publicId == null) {
      router.go(-1);
    }
    // Policy information route based on user roles
    if (userType.role == variables.FINANCE_USER_ROLE) {
      // Finance role policy information route
      this.route = `${variables.FINANCE_CREDIT_NOTE_DETAILS_ROUTE}/${this.publicId}`;
    } else if (
      userType.role == variables.ADMIN_USER_ROLE ||
      userType.role == variables.SUPER_ADMIN_USER_ROLE
    ) {
      // Super Admin & Admin role policy information route
      this.route = `${variables.SUPERADMIN_SETTLE_CREDIT_NOTE_ROUTE}/${this.publicId}`;
    }

    this.getCreditNoteInformation();
  },
  methods: {
    getCreditNoteInformation() {
      //Fetch individual vehicle policy information
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(this.route)
          .then(({ data }) => {
            //Assign data to variables
            this.policySummary = data.data;
            this.premium = data?.data?.netPremium;
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    capitalizeWord(value) {
      return variables.capitalizeWord(value);
    },
    addCommaToNumber(amount) {
      return variables.addCommaToNumber(amount);
    },
    addCommaToNumberString(amount) {
      return variables.addCommaToNumberString(amount);
    },
    formatDate(dateString) {
      return variables.formatDate(dateString);
    },
  },
});
